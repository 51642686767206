<template>
  <v-card>
    <div class="card-header-padding">
      <div class="d-flex align-center">
        <div>
          <h5 class="font-weight-bold text-h5 text-typo mb-0">Xичээлийн ирц</h5>
          <p class="text-sm text-body mb-0">
            Сурагчдын ирцийг бүртгэx. Ирсэн: 1, Тодорxойгүй: 0, Xоцорсон: -1,
            Чөлөөтэй: -2, Өвчтэй: -3, Тасалсан: -4
          </p>
        </div>
      </div>
    </div>

    <!-- <p v-for="(student, sindex) in users2" :key="sindex">{{student.firstName}}</p> -->
    <div class="card-header-padding">
      <v-data-table
        :headers="headers2"
        :items="users2"
        hide-default-header
        hide-default-footer
        :items-per-page="-1"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th
                v-for="(headDay, hIndex) in headers"
                :key="'th' + hIndex"
                class="text-center"
              >
                <span>{{ headDay.text }}</span>
              </th>
            </tr>
          </thead>
        </template>

        <template slot="item" slot-scope="props">
          <tr :id="props.item.id">

            <template v-if="currentDays">
              <td
                v-for="day in currentDays"
                :key="'td' + day"
                style="cursor: pointer; background: red"
                :style="
                  _getStatus(props.item, day) == 1
                    ? 'background:#B0ED63;'
                    : _getStatus(props.item, day) == 0 //Тодорxойгүй
                    ? 'background:#bbb'
                    : _getStatus(props.item, day) == -1 //Xоцорсон
                    ? 'background:#87B3FC'
                    : _getStatus(props.item, day) == -2 //Чөлөөтэй
                    ? 'background:yellow'
                    : _getStatus(props.item, day) == -3 //Өвчтэй
                    ? 'background:#FFA500; color:white'
                    : _getStatus(props.item, day) == -4 //Тасалсан
                    ? 'background:red; color:white'
                    : ''
                "
                class="text-center"
              >
                {{ _getStatus(props.item, day) }}
              </td>
            </template>
          </tr>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import myfunction from "@/components/global/myfunction.js";
export default {
  components: {},
  data: () => ({
    headerNames: null,
    currentDays: null,
  }),
  props: {
    students: {
      type: Object,
      required: true,
    },
    lesson: {
      type: Object,
      required: true,
    },
    selectedMonth: {
      type: Number,
      required: true,
    },
  },
  mixins: [myfunction],
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    headers2() {
      return this.headerNames;
    },
    users2() {
      var list = [];
      list.push(this.students);
      return list;
    },
  },
  created() {
    this.headerNames = [
    
    ];
    this.currentDays = new Date(2021, this.selectedMonth, 0).getDate();
    for (var i = 0; i < this.currentDays; i++) {
      this.headerNames.push({
        text: i + 1,
        align: "center",
        value: i + 1,
        sortable: true,
        dday: true,
      });
    }
  },
  watch: {
    selectedMonth(val1) {
      this.headerNames = [
        {
          text: "No",
          align: "end",
          value: "index",
          sortable: true,
          width: "2%",
          fixed: true,
        },
        {
          text: "Нэр",
          align: "start",
          sortable: true,
          value: "firstName",
          fixed: true,
          width: "20%",
        },
      ];
      this.currentDays = new Date(2021, val1, 0).getDate();
      for (var i = 0; i < this.currentDays; i++) {
        this.headerNames.push({
          text: i + 1,
          align: "center",
          value: i + 1,
          sortable: true,
        });
      }
    },
  },

  methods: {
    _getStatus(student, day) {
      if (student.attendances != null && student.attendances != undefined) {
        for (const attend of student.attendances) {
          if (attend.day == day && attend.month == this.selectedMonth) {
            return attend.status;
          }
        }
      }
      return 1;
    },
  },
};
</script>
<style>
.scoreTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
</style>
