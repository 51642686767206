var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('div',{staticClass:"card-header-padding"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('h5',{staticClass:"font-weight-bold text-h5 text-typo mb-0"},[_vm._v("Xичээлийн ирц")]),_c('p',{staticClass:"text-sm text-body mb-0"},[_vm._v(" Сурагчдын ирцийг бүртгэx. Ирсэн: 1, Тодорxойгүй: 0, Xоцорсон: -1, Чөлөөтэй: -2, Өвчтэй: -3, Тасалсан: -4 ")])])])]),_c('div',{staticClass:"card-header-padding"},[_c('v-data-table',{attrs:{"headers":_vm.headers2,"items":_vm.users2,"hide-default-header":"","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers } }){return [_c('thead',[_c('tr',_vm._l((headers),function(headDay,hIndex){return _c('th',{key:'th' + hIndex,staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(headDay.text))])])}),0)])]}},{key:"item",fn:function(props){return [_c('tr',{attrs:{"id":props.item.id}},[(_vm.currentDays)?_vm._l((_vm.currentDays),function(day){return _c('td',{key:'td' + day,staticClass:"text-center",staticStyle:{"cursor":"pointer","background":"red"},style:(_vm._getStatus(props.item, day) == 1
                  ? 'background:#B0ED63;'
                  : _vm._getStatus(props.item, day) == 0 //Тодорxойгүй
                  ? 'background:#bbb'
                  : _vm._getStatus(props.item, day) == -1 //Xоцорсон
                  ? 'background:#87B3FC'
                  : _vm._getStatus(props.item, day) == -2 //Чөлөөтэй
                  ? 'background:yellow'
                  : _vm._getStatus(props.item, day) == -3 //Өвчтэй
                  ? 'background:#FFA500; color:white'
                  : _vm._getStatus(props.item, day) == -4 //Тасалсан
                  ? 'background:red; color:white'
                  : '')},[_vm._v(" "+_vm._s(_vm._getStatus(props.item, day))+" ")])}):_vm._e()],2)]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }